import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,2,5,3,4,7];

export const dictionary = {
		"/": [~8],
		"/about-us": [9],
		"/character": [10,[2]],
		"/character/create": [~11,[2]],
		"/design": [12],
		"/design/create": [14],
		"/design/p/[slug]": [15,[5]],
		"/design/[slug]/edit": [~13,[3,4]],
		"/image": [~16],
		"/landing": [17],
		"/login": [~18],
		"/policies/cookies": [19,[6]],
		"/policies/privacy": [20,[6]],
		"/policies/terms": [21,[6]],
		"/pricing": [22],
		"/project/[slug]": [23],
		"/settings": [24,[7]],
		"/settings/profile": [25,[7]],
		"/subscribe": [26],
		"/user": [27]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';